import { HttpMethods } from '../../enums';
import { restApi } from '../common';
import {
  CreateFootprintTokenResponse,
  FootprintValidationParams,
  CreateFootprintTokenParams,
  FootprintValidationResponse,
  RefreshIdentityVerificationStatusParams,
} from './identity.model';

const BASE_URL = '/api/identity';

export const identityApi = restApi.injectEndpoints({
  endpoints: builder => {
    const validateFootprintToken = builder.mutation<
      FootprintValidationResponse,
      FootprintValidationParams
    >({
      query: ({ investorEntityId, token }) => ({
        url: `${BASE_URL}/public/footprint/investor-entity/${investorEntityId}/validate`,
        method: 'POST',
        data: {
          token,
        },
      }),
    });

    const createFootprintToken = builder.mutation<
      CreateFootprintTokenResponse,
      CreateFootprintTokenParams
    >({
      query: ({ investorEntityId }) => ({
        url: `${BASE_URL}/public/investor-entity/${investorEntityId}/footprint/session`,
        method: HttpMethods.Post,
      }),
    });

    const refreshIdentityVerificationStatus = builder.mutation<
      {},
      RefreshIdentityVerificationStatusParams
    >({
      query: ({ investorEntityId }) => ({
        url: `${BASE_URL}/public/investor-entity/${investorEntityId}/refresh-status`,
        method: HttpMethods.Post,
      }),
    });

    return {
      validateFootprintToken,
      createFootprintToken,
      refreshIdentityVerificationStatus,
    };
  },
});
