import { experimental_extendTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

import { light, dark } from './schemes';
import { components } from './components';

const cssTHEME = experimental_extendTheme({
  colorSchemes: {
    light,
    dark,
  },
  cssVarPrefix: 'ys',
  components,
  spacing: 4,
  breakpoints: {
    values: {
      xs: 444,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Linik Sans, Arial, sans-serif',
    button: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
});

export const CSS_THEME = cssTHEME;
