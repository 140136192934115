import styled from 'styled-components';

import { Container } from '../container';
import { PageContainerBackgrounds } from './PageContainer.model';

export const PageContainerWrapper = styled.div<{ background?: string; light: boolean }>`
  background: ${({ background, light, theme }) => {
    /**
     * Remove line 10 and 22 (if condition)
     * when `light` prop is no longer used.
     */
    if (background) {
      switch (background) {
        case PageContainerBackgrounds.Dark:
          return theme.colors.background_page;

        case PageContainerBackgrounds.Light:
          return theme.colors.background_default;

        case PageContainerBackgrounds.None:
        default:
          return 'none';
      }
    }

    /**
     * Deprecated. Used for backward compatibility.
     * Remove when `light` prop is no longer used.
     */
    return light ? theme.colors.background_default : theme.colors.background_page;
  }};
`;

export const PageContainerContent = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 35px;
  padding-bottom: 35px;

  &:last-child {
    padding-bottom: 65px;
  }
`;
