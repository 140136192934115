import React from 'react';

import { PageContainerContent, PageContainerWrapper } from './PageContainer.style';
import { PageContainerProps, useTestIds } from './PageContainer.model';

export const PageContainer = ({
  background,
  children,
  id,
  light = false,
  testId,
  ...containerProps
}: PageContainerProps) => {
  const testIds = useTestIds({ testId: testId || id || '' });

  return (
    <PageContainerWrapper background={background} light={light} {...testIds.base.attr}>
      <PageContainerContent {...containerProps}>{children}</PageContainerContent>
    </PageContainerWrapper>
  );
};
